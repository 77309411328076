const HeroExtra = () => {
  return (
    <>
      <header>
        <h2>¡SOMOS MAYORISTAS!</h2>
      </header>
      <p>¡Invierte con nosotros y obtén hasta el 50% en gananacias!</p>
      <p>
        Además, puedes obtener grandiosos beneficios por distribuir nuestros
        accesorios.
      </p>
    </>
  );
};
export default HeroExtra;
